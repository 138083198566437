import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import {
  PropsWithChildren,
  ReactNode,
  useRef,
  useState,
} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classNames from "classnames";
//import anim from "./anim.json";
import { useGSAP } from '@gsap/react';

import formatDate from "utils/formatDate";
//import quotes from ".data/contentful/customerQuote/all.json"
import Footer from "@components/Footer";
import Form from "@components/Email";
import Button from "@components/Button";

import blogPostFields from "utils/getBlogPosts";
import ReactPlayer from "react-player/file";

import { FcAbout } from "react-icons/fc";
import {
  FaChartLine,
  FaChessKnight,
  FaCloud,
  FaCloudscale,
  FaCode,
  FaFileCode,
  FaFrog,
  FaLanguage,
  FaPerson,
  FaRegComment,
  FaTachographDigital,
  FaUser,
  FaLock,
} from "react-icons/fa6";
import * as Icons from "../icons/index";

import ExampleAI from "@components/Logos/ExampleAI";
import { MdArrowDropDown, MdOutlineAccessTime } from "react-icons/md";
import Link from "next/link";
import Image from "@components/Image";
import SEO from "@components/SEO";
import Script from "next/script";
import NoSSR from "@components/NoSSR";
import dynamic from "next/dynamic";
import { StaticImageData } from "next/image";
import CustomerCard from "@components/CustomerCard";
import Swiper from "@components/Swiper";
import { FaArrowRight } from "react-icons/fa";
import BlogCard from "@components/BlogCard";
import TechStack from "@components/TechStack";
import Topbar from "@components/Topbar/Topbar";
import { Container } from "@components/Container";
import IconGrid from "@components/IconGrid";

const titleClassesWrap = "text-3xl font-thin text-custom-text";
const reactivePadding = "p-[5vw] md:px-[15vh] max-w-[1920px]"

const gridStyle =
  "aspect-square bg-custom-white flex items-center justify-center transition-all duration-300 hover:shadow-sm hover:shadow-black hover:scale-105";



// const Label = (props: { children: ReactNode }) => {
//   const { children } = props;
//   return (
//     <Content>
//       <div className={classNames("relative w-full py-8 md:py-12 lg:py-16", className)}>
//         <h3
//           className={classNames(
//             "text-lg sm:text-xl md:text-2xl w-fit py-2 px-3 md:px-4 mb-4 md:mb-6 lg:mb-8",
//             titleColorClass,
//             backgroundColorClass,
//             borderColorClass,
//             {
//               "ml-auto": titleAlignment === "right",
//             }
//           )}
//         >
//           {title}
//         </h3>
//         {children}
//       </div>
//     </Content>
//   );
// };

export const Label = (props: {
  title: string;
  className?: string;
  titleColorClass?: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
  children?: React.ReactNode;
}) => {
  const {
    title,
    className,
    titleColorClass = "text-custom-text",
    backgroundColorClass = "bg-custom-bg",
    borderColorClass = "border-custom-button",
    children
  } = props;

  return (
    <Content>
      <div className={classNames(className)}>
        <h3
          className={classNames(
            `w-fit py-[1vh] px-3 
            mobile:mb-[2vh] 
            sm:text-md 
            md:text-2xl 
            md:px-4 
            md:mb-6 
            lg:mb-8
            lg:text-[1vw]`,
            titleColorClass,
            backgroundColorClass,
            borderColorClass,
          )}
        >
          {title}
        </h3>
      </div>
    </Content>
  );
};

export const SectionWithLabel = (props: {
  title: string;
  className?: string;
  children: ReactNode;
  titleAlignment?: "left" | "right";
  titleColorClass?: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
}) => {
  const {
    title,
    children,
    titleAlignment = "left",
    className,
    titleColorClass = "text-custom-text",
    backgroundColorClass = "bg-custom-bg",
    borderColorClass = "border-custom-button",
  } = props;

  return (
    <Content>
      <div className={classNames("relative w-full md:py-12 lg:py-16", className)}>
        <TextWrap>
          <h3
            className={classNames(
              "text-lg sm:text-xl md:text-2xl w-fit py-2 px-3 md:px-4 mobile:mb-[1vh] md:mb-6 lg:mb-8",
              titleColorClass,
              backgroundColorClass,
              borderColorClass,
              {
                "ml-auto": titleAlignment === "right",
              }
            )}
          >
            {title}
          </h3>
        </TextWrap>

        {children}
      </div>
    </Content>
  );
};

export const Content = (
  props: PropsWithChildren<{ className?: string; fullWidth?: boolean }>
) => {
  const { children, className, fullWidth = false } = props;

  return (
    <div className="w-full max-w-[1920px]">
      <section
        className={classNames(className, {
          "w-full": !fullWidth,
        })}
      >
        {children}
      </section>
    </div>
  );
};


function App() {
  const container = useRef(null);
  const circle = useRef(null);

  useGSAP(() => {
    // use selectors...
    gsap.to(".box", { rotation: "+=360", duration: 3 });

    // or refs...
    gsap.to(circle.current, { rotation: "-=360", duration: 3 });
  },
    { scope: container }
  ); // <-- scope for selector text (optional)

  return (
    <div className="App">
      <div ref={container} className="container">
        <div className="box gradient-blue">selector</div>
        <div className="circle gradient-green" ref={circle}>
          Ref
        </div>
      </div>
      <div className="box gradient-blue">selector</div>
    </div>
  );
}

const PulsingIcon = () => {
  const container = useRef(null);
  const lock = useRef(null);
  useGSAP(
    () => {
      // gsap code here...
      gsap.to(".child", {
        opacity: 0.5,
        duration: 3,
        stagger: {
          each: 0.2,
          repeat: -1
        }
      });

      // gsap.to("#first", { opacity: "-=1.0", duration: 3, repeat: Infinity });
    },
    { scope: container }
  );

  return <>
    <svg ref={container} width="228" height="228" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="113.976" cy="113.96" r="66.0385" fill="#002FA7" />
      <circle className="child" cx="113.976" cy="113.96" r="90.5838" fill="#002FA7" fill-opacity="0.4" />
      <circle className="child" cx="113.976" cy="113.96" r="113.96" fill="#002FA7" fill-opacity="0.1" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M125.998 107.066L102.393 107.066C100.515 107.066 98.7141 107.815 97.3861 109.149C96.0581 110.484 95.312 112.293 95.312 114.18V130.78C95.312 132.667 96.0581 134.476 97.3861 135.81C98.7141 137.145 100.515 137.894 102.393 137.894H125.998C127.876 137.894 129.677 137.145 131.005 135.81C132.333 134.476 133.079 132.667 133.079 130.78V114.18C133.079 112.293 132.333 110.484 131.005 109.149C129.677 107.815 127.876 107.066 125.998 107.066ZM127.667 132.457C128.11 132.012 128.358 131.409 128.358 130.78V114.18C128.358 113.551 128.11 112.948 127.667 112.503C127.224 112.058 126.624 111.808 125.998 111.808H102.393C101.767 111.808 101.167 112.058 100.724 112.503C100.282 112.948 100.033 113.551 100.033 114.18V130.78C100.033 131.409 100.282 132.012 100.724 132.457C101.167 132.902 101.767 133.151 102.393 133.151H125.998C126.624 133.151 127.224 132.902 127.667 132.457Z"
        fill="#ECF5FF" />

      <path ref={lock} onClick={() => {
        gsap.to(lock.current, { duration: 2, rotate: 45, transformOrigin: "bottom right" })
      }}
        d="M125.998 111V102.323C125.998 99.1781 124.754 96.1622 122.541 93.9385C120.328 91.7149 117.326 90.4656 114.196 90.4656C111.065 90.4656 108.064 91.7149 105.85 93.9385C103.637 96.1622 102.393 99.1781 102.393 102.323V108C103.516 107.985 106.916 107.952 107 108L107.114 102.323C107.114 100.436 107.86 98.6264 109.188 97.2922C110.516 95.958 112.318 95.2085 114.196 95.2085C116.074 95.2085 117.875 95.958 119.203 97.2922C120.531 98.6264 121.277 100.436 121.277 102.323V111H125.998Z" fill="#ECF5FF" />
    </svg>
  </>
}

export const Expand = (props: { children?: ReactNode; label?: ReactNode }) => {
  const { children, label } = props;
  const [expanded, setExpaded] = useState(false);

  return (
    <div className="border flex flex-col p-3">
      <div
        onClick={() => {
          setExpaded(!expanded);
        }}
        className={classNames("flex w-full cursor-pointer items-center", {
          "": expanded,
        })}
      >
        <div className="flex-1">{label}</div>
        <div className="flex-0">
          <MdArrowDropDown
            className={classNames("w-8 h-8 transition-all", {
              "rotate-180": expanded,
            })}
          />
        </div>
      </div>
      <div className={classNames("mt-4", { hidden: !expanded })}>
        {children}
      </div>
    </div>
  );
};


export const Row = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;

  return (
    <div
      className={classNames("flex items-stretch w-full md:flex-row", className)}
    >
      {children}
    </div>
  );
};


const EmailSignup = () => {
  return (
    <Script id="mailer-light">
      {`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '691954');
      `}
    </Script>
  );
};

const Column = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;

  return (
    <div
      className={classNames(
        "flex flex-col items-stretch w-full md:flex-row",
        className
      )}
    >
      {children}
    </div>
  );
};

const iconsLeft = [Icons.left1, Icons.left2, Icons.left3];
const iconsRight = [Icons.right3, Icons.right2, Icons.right1];
const icons = [
  Icons.a1,
  Icons.a2,
  Icons.a3,
  Icons.a4,
  Icons.a5,
  Icons.a6,
  Icons.a7,
  Icons.a8,
  Icons.a9,
  //
  Icons.a10,
  Icons.a11,
  Icons.a12,
  Icons.a13,
  Icons.a14,
  Icons.a15,
  Icons.a16,
  Icons.a21,
  Icons.a18,
  //
  Icons.a19,
  Icons.a20,
  Icons.a21,
  Icons.a22,
  Icons.a23,
  Icons.a24,
  Icons.a6,
  Icons.a7,
  Icons.a2,
  //
  Icons.a12,
  Icons.a2,
  Icons.a14,
  Icons.a5,
  Icons.a8,
  Icons.a19,
  Icons.a16,
  Icons.a1,
  Icons.a5,
];




export const TextWrap = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;
  return (
    <div className={classNames(className, "mobile:px-[0vh] mobile:py-[0vh] sm:py-[1vh] sm:px-0 md:px-0 md:py-[1vh]")} >
      {children}
    </div >
  )
}

export const ParagraphText = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <TextWrap>
      <p className={classNames(props.className, `text-pretty
                mobile:text-left
                mobile:text-[5vw]  
                sm:text-[3.5vw]
                md:text-start 
                md:px-0
                md:text-[1.5vw]
                lg:text-[3vw]
                leading-tight
                lg:font-bold`)}>
        {props.children}
      </p>
    </TextWrap>
  )
}

export function Home() {
  return (
    <>
      <div className="flex flex-col mt-[25vh] sm:items-center relative">
        <Topbar></Topbar>
        <EmailSignup></EmailSignup>

        <SEO
          title="AWS Specialister - molntjänster, AI och automatisering"
          description="Vi är ett konsultbolag specialiserat på molntjänster, AI och automatisering, med fokus på AWS.."
        />

        {/* Title */}
        <Content className="md:h-screen mobile:mb-[20vh] sm:mb-0">
          <div className={`${reactivePadding} flex md:gap-12  sm:flex-col md:flex-row h-full justify-between `}>
            {/* Left Section (Text and Icons) */}
            <div className="flex flex-grow flex-col w-fit
            mobile:gap-10
            mobile:items-start 
            mobile:justify-center
            
            md:gap-0  
            md:items-start 
            md:justify-start 
            ">
              {/* Title */}
              <TextWrap className="">
                <h2 className="flex flex-col leading-tight 
                mobile:justify-center 
                mobile:items-center
          
                md:justify-start
                md:items-start
                
                mobile:text-[10vw]
                md:text-[4.5vw]
                ">
                  {/* Row 1 */}
                  <div className="flex items-center justify-center w-full">
                    <h2 className="whitespace-pre">aws partner for </h2>
                    <Image
                      className="inline-block w-4 h-4 w-[1em] h-[1em]"
                      alt="AWS Logo"
                      src={Icons.titleAWSLogo}
                    />
                  </div>

                  {/* Row 2 */}
                  <div className="flex items-center justify-center w-full">
                    <h2 className="whitespace-pre">secure </h2>
                    <Image
                      className="inline-block md:h-[0.7em]
                      sm:w-[0.8em] sm:h-[0.7em]"
                      alt="Lock Icon"
                      src={Icons.titleLock}
                    />
                    <h2 className="whitespace-pre ml-1"> scalable</h2>
                  </div>

                  {/* Row 3 */}
                  <div className="flex items-center mobile:justify-center w-full">
                    <Image
                      className="inline-block
                      sm:w-[0.8em] sm:h-[0.7em]"
                      alt="Cloud Icon"
                      src={Icons.titleCloud}
                    />
                    <h2 className="whitespace-pre"> cloud solutions</h2>
                  </div>
                </h2>
              </TextWrap>

              {/* Description */}

              <ParagraphText className="lg:text-[1.4rem] sm:text-[3vw] leading-tight">Vi hjälper er att bygga moderna applikationer med den senaste molntekniken.</ParagraphText>

              {/* Button */}
              <Button
                icon={<FaArrowRight />}
                className="w-fit bg-custom-button
                mobile:h-[8vh] 
                sm:mt-[6vh]
                md:h-[9vh]  "
                label="Jobba med en aws specialist"
                onClick={(e) => {
                  e.preventDefault();
                  gsap.to(window, {
                    duration: 0,
                    scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                  });
                }}
              />


            </div>

            {/* Right Section (Image and Badge) */}
            <div className="flex max-w-[40vw]">
              {/* Main Image */}
              <div className="">
                <Image
                  alt=""
                  src="/images/main.jpeg"
                  className="hidden md:block object-cover object-center"
                  width={4000}
                  height={4000}
                />
              </div>
            </div>
          </div >
        </Content >

        <div id="SYFTE"></div>
        <Content className={`${reactivePadding} mobile:w-full sm:w-3/4 mb-[10vh] mx-auto flex justify-center`}>
          <Content
            className=""
          >
            <Label
              backgroundColorClass="bg-custom-bg-tint"
              titleColorClass="text-custom-button-text"
              title="En ny form av säkerhet">
            </Label>
            <div className="flex flex-col gap-12">
              <ParagraphText>Våra ledord - tillit, enkelhet och samarbete - genomsyrar allt
                vi gör. Genom att leverera säkerhet som är lättillgänglig och
                robust, arbetar vi tillsammans med dig för att bygga långsiktig
                trygghet och tillväxt för din verksamhet.
              </ParagraphText>

              {/* Icons mid - TODO */}
              <div className="flex flex-row items-center justify-center scale-125">

                <div className="flex flex-shrink-0 flex-grow justify-end flex-row gap-[1.5vw] mobile:invisible md:visible">
                  <Image className="scale-150" alt="" src={iconsLeft[0]} />
                  <Image className="scale-110" alt="" src={iconsLeft[1]} />
                  <Image className="" alt="" src={iconsLeft[2]} />
                </div>

                {/* Lock Icon Centered */}
                <div className="sm:flex-shrink-0 justify-center items-center p-[1vw]">
                  <PulsingIcon></PulsingIcon>
                </div>

                <div className="flex flex-shrink-0 flex-grow gap-10 justify-start flex-row gap-[1.5vw] mobile:invisible md:visible">
                  <Image className="" alt="" src={iconsRight[0]} />
                  <Image className="scale-110" alt="" src={iconsRight[1]} />
                  <Image className="scale-150" alt="" src={iconsRight[2]} />
                </div>

              </div>

              {/* Icons grid */}
              <div className="min-w-fit">
                <IconGrid columns={9} icons={icons} invertColors={true} />
              </div>
            </div>
          </Content>
        </Content>

        <div id="KONSULTER"></div>
        <div className={`${reactivePadding} flex flex-col md:flex-row w-full mobile:h-fit lg:h-screen sm:h-fit justify-center items-center bg-custom-button`}>
          {/* Full-width background wrapper */}
          <Content>
            <div className="flex flex-col md:flex-row justify-between w-full sm:pt-[5vh] sm:pb-[5vh] md:pt-0">

              {/* Left Box (Text Content) */}
              <TextWrap>
                <div className="flex flex-col">

                  <Label
                    title="Konsulter"
                    backgroundColorClass="bg-custom-button-2-tint"
                    titleColorClass="text-custom-button-2"
                    className="mobile:mb-[3vh]">
                  </Label>
                  <div className="flex items-center">
                    <h2 className="flex text-white flex-col text-[6vw] sm:text-[7vw] md:text-[4vw] lg:text-[4vw] leading-tight mb-[3vh]">
                      <TextWrap>
                        <div className="flex">
                          <span className="">AWS-certifierade</span>
                        </div>

                        <div className="flex">
                          <span className="">säkerhetsspecialister</span>
                        </div>
                      </TextWrap>
                    </h2>
                  </div>
                  <ParagraphText className="text-custom-white lg:text-[1.2rem] lg:w-3/4">
                    Våra konsulter är specialiserade på att bygga säkra molnlösningar. Som en del av Amazon Partner Network
                    använder vi den senaste tekniken och de bästa metoderna för
                    att hjälpa företag maximera värdet av sina molninvesteringar.
                  </ParagraphText>

                  <TextWrap>
                    <div className="flex mt-6 gap-4 h-14">
                      <Button
                        className="h-14 lg:h-[9vh] bg-custom-button-2"
                        textColor="black"
                        label="Jobba med en aws specialist"
                        onClick={(e) => {
                          e.preventDefault();
                          gsap.to(window, {
                            duration: 0,
                            scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                          });
                        }}
                      />
                    </div>
                  </TextWrap>
                </div>
              </TextWrap>

              {/* Right Box (box2) */}
              <div className="flex flex-col flex-grow justify-center items-end">
                <Image
                  alt="Person working on AWS solutions"
                  className="hidden md:block lg:w-[75vw] md:w-[100vw] h-auto shadow-2xl object-cover"
                  width={432}
                  height={432}
                  src="/images/persons/erik_rehn.jpeg"
                />
              </div>

            </div>
          </Content>
        </div>



        <div id="KUNDER"></div>
        <Content className={`${reactivePadding} bg-custom-white`}>
          <Label
            backgroundColorClass="bg-custom-bg-tint"
            titleColorClass="text-custom-button-text"
            title="Kunder"
          >
          </Label>
          <TextWrap>
            <h1 className="font-thin text-custom-text 
              mobile:text-[5vw] 
              mobile:mb-[2vh]

              sm:mt-[0vh]
              sm:mb-[0vh]
  
              md:text-[3vw]
              lg:mb-[5vh]">
              Upptäck våra kunders resor
            </h1>
          </TextWrap>

          <CustomerCard />
        </Content>

        <div id="OMOSS"></div>
        <div className="w-screen">
          <Content
            className={`${reactivePadding}`}
          >
            {/* Content */}
            <Label
              backgroundColorClass="bg-custom-bg-tint"
              titleColorClass="text-custom-button-text"
              title="Om oss">
            </Label>
            <div className="flex mobile:flex-col md:flex-row mobile:gap-[2vh] md:gap-10">

              <div className="flex basis-[60%]">
                <div className="relative w-full h-full m-auto shadow-lg">
                  <Image
                    alt=""
                    className="h-full"
                    src="/images/persons/gruppbild.jpg"
                    width={4032}
                    height={4032}
                  />
                </div>
              </div>

              {/* Text */}
              <div className="flex flex-col basis-1/2 mobile:gap-[2vh] sm:gap-0">
                {/* Text content */}
                <div className="flex flex-col gap-5">
                  <ParagraphText className="lg:text-custom-black lg:text-[1.3rem] lg:leading-7">
                    Altostruct grundades 2020 med en vision att skapa säkra och
                    skalbara molnlösningar för alla. Sedan dess har vi hjälpt
                    över 20 företag att migrera och utveckla robusta
                    molnlösningar anpassade till dagens höga krav på säkerhet
                    och flexibilitet.
                  </ParagraphText>

                  <ParagraphText className="lg:text-custom-black lg:text-[1.3rem] lg:leading-7">
                    Med serviceinriktning och fokus på nära samarbete är vi en
                    engagerad partner som strävar efter att skapa långsiktigt
                    värde och kvalitet i varje projekt.
                  </ParagraphText>
                </div>

                {/* Buttons */}
                <div className="flex flex-grow lg:gap-3 items-center 
                justify-between
                mobile:flex-row
                sm:mt-[3vh]
                md:justify-between
                lg:items-end
              
                ">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      gsap.to(window, {
                        duration: 0,
                        scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                      });
                    }}
                    label="Kontakta oss"
                    className="h-14 px-5"
                  ></Button>

                  <Button
                    className="h-14 px-5"
                    textColor="black"
                    variant="custom"
                    icon={<FaArrowRight />}
                    link="https://www.linkedin.com/company/altostruct/jobs/"
                    label="Arbeta med oss"
                  />
                </div>
              </div>
            </div>
          </Content>
        </div>

        <div id="NYHETER"></div>
        <div className="w-screen bg-custom-white">
          <SectionWithLabel
            backgroundColorClass="bg-custom-bg-tint"
            titleColorClass="text-custom-button-text"
            title="Nyheter"
            className={`${reactivePadding}`}
          >
            {/* Content */}
            <div className="">
              <div className="flex justify-between">
                {/* TODO: text */}
                <span></span>
                <span> {blogPostFields.length}+ artiklar</span>
              </div>

              {/* Blogposts */}
              <div className="mt-5">
                <BlogCard></BlogCard>
              </div>
            </div>
          </SectionWithLabel>
        </div>

        <div id="KONTAKTAOSS"></div>
        <div className="w-screen">
          <SectionWithLabel
            backgroundColorClass="bg-custom-bg-tint"
            titleColorClass="text-custom-button-text"
            title="Kontakt"
            className={`${reactivePadding}`}
          >
            <h1 className={classNames(titleClassesWrap, "mb-5")}></h1>

            {/* Content */}
            <div className="flex 
            mobile:flex-col 
            sm:flex-row
            mobile:gap-[3vh]">
              {/* Text */}
              <div className="flex flex-grow flex-col md:mt-10">
                <div className="flex flex-col ">
                  <p className={`
                      lg:text-[1.2vw]
                      md:text-[1.4vw]
                      sm:text-[2vw]
                    `}>{"Erik Rehn"}</p>
                  <p className={`
                      lg:text-[1.2vw]
                      md:text-[1.4vw]
                      sm:text-[2vw]
                    `}>{"VD & Co founder"}</p>
                </div>
                <div className="flex flex-grow flex-col justify-end">
                  <p className={`
                      lg:text-[1.2vw]
                      md:text-[1.4vw]
                      sm:text-[2vw]
                    `}>{"+46 708 66 03 40"}</p>
                  <p className={`
                      lg:text-[1.2vw]
                      md:text-[1.4vw]
                      sm:text-[2vw]
                    `}>{"erik@altostruct.se"}</p>
                </div>
              </div>

              {/* Image */}
              <div className="flex flex-grow flex-row items-center justify-center">
                <Image
                  alt=""
                  className="shadow-lg w-fit h-fit sm:max-w-[29vw] aspect-custom-aspect overflow-clip object-cover"
                  width={4000}
                  height={4000}
                  src={"/images/persons/erik_rehn.jpeg"}
                />
              </div>

              {/* Text + button */}
              <div className="flex md:mt-10
              flex-col
              mobile:justify-between 
              mobile:items-start             
              md:basis-2/5
              md:px-10 
              md:justify-between
              md:items-start
              
              ">
                {/* Text */}
                <div className="flex flex-col">
                  <h2 className="mobile:text-[5vw] 
                  lg:text-[1.2vw]
                  md:text-[1.4vw]
                  sm:text-[2vw]
                  ">
                    Redo att säkra din AWS-lösning?
                  </h2>
                </div>
                {/* Button */}
                <div className="flex items-end ">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "mailto:erik@altostruct.se";
                    }}
                    className="mobile:mt-[1vh] md:mt-0"
                    label="Kontakta oss"
                  />
                </div>
              </div>
            </div>
          </SectionWithLabel>
        </div>

      </div >
      <Footer className={"px-[8vw]"}></Footer>
    </>
  );
}

export default Home;
