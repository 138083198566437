import { useState, useEffect } from 'react';
import Image, { StaticImageData } from 'next/image'; // Assuming you're using Next.js Image component
import classNames from 'classnames';

type IconGridProps = {
    columns?: number;
    icons: (string | StaticImageData)[];
    fitContent?: boolean; // Controls content-based sizing
    invertColors?: boolean; // Controls color inversion
    size?: number;
};

const IconGrid = ({
    columns = 9,
    icons = [],
    fitContent = false,
    invertColors = false,
    size = 1,
}: IconGridProps) => {
    const [visibleIcons, setVisibleIcons] = useState(icons);

    useEffect(() => {
        const updateVisibleIcons = () => {
            const width = window.innerWidth;

            if (width >= 1024) {
                // Large screens (lg)
                setVisibleIcons(icons.slice(0, 36));
            } else if (width >= 768) {
                // Medium screens (md)
                setVisibleIcons(icons.slice(0, 16));
            } else {
                // Small screens (sm and below)
                setVisibleIcons(icons.slice(0, 8));
            }
        };

        updateVisibleIcons(); // Initial call
        window.addEventListener('resize', updateVisibleIcons);

        return () => window.removeEventListener('resize', updateVisibleIcons);
    }, [icons]);

    return (
        <div
            className={classNames(
                "grid grid-cols-4 lg:gap-3 md:gap-2 sm:gap-2 gap-1",
                {
                    "grid-cols-4": fitContent && columns === 4,
                    "md:grid-cols-9": !fitContent,
                    "lg:grid-cols-9": !fitContent,
                }
            )}
        >
            {visibleIcons.map((icon: any, index) => (
                <div
                    key={index}
                    className={classNames(
                        "flex items-center justify-center",
                        invertColors ? "invert" : "",
                        fitContent ? "w-fit" : "aspect-square bg-[#130A00]"
                    )}
                >
                    {typeof icon === "string" ? (
                        <span className="text-xl">{icon}</span>
                    ) : (
                        <Image
                            src={icon}
                            alt="icon"
                            loader={({ src }) => src as string}
                            className={`object-contain w-fit ${fitContent ? "h-fit" : "h-3/5"}`}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default IconGrid;
