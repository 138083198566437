import * as React from "react";
import Link from "next/link";
import Image from "@components/Image";
import { useRouter } from "next/router";
import { gsap } from "gsap";
import AnimatedText from "../../components/AnimatedText/index";
import Button from "@components/Button"; // Assuming Hamburger is in a separate file for readability
import { FaArrowRight } from "react-icons/fa";
import classNames from "classnames";
import { TextWrap } from "pages";

const AnimatedLine = (props: { expanded: boolean }) => {
  const { expanded } = props;
  const [isExpanded, setIsExpanded] = React.useState(expanded);
  const ref = React.useRef<SVGLineElement>(null);

  if (ref.current) {
    const length = ref.current.getTotalLength();
    if (expanded) {
      ref.current.animate(
        [
          {
            strokeDashoffset: 0,
          },
        ],
        { duration: 400, easing: "ease-out", iterations: 1, fill: "forwards" },
      );
    } else {
      ref.current.animate(
        [
          {
            strokeDashoffset: length,
          },
        ],
        { duration: 400, easing: "ease-out", iterations: 1, fill: "forwards" },
      );
    }
  }
  return (
    <svg
      className="absolute left-0 bottom-0"
      width="100%"
      preserveAspectRatio="none"
      height="1px"
      viewBox="0 0 100 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        strokeDasharray={100}
        strokeDashoffset={100 + ""}
        ref={ref}
        x1="0"
        y1="0"
        x2="100"
        y2="0"
        stroke="black"
      />
    </svg>
  );
};

function Hamburger(props: { expanded: boolean; onClick: () => void }) {
  const { expanded, onClick } = props;

  return (
    <svg
      onClick={onClick}
      className="cursor-pointer transition-all"
      width="24"
      height="24"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classNames("transition-all")}
        d={!expanded ? "M0.5 0.5 5.5 0.5" : "M0.5 0.5 5.5 5.5"}
        stroke="black"
        stroke-width="1"
        stroke-linecap="round"
      />

      <path
        className={classNames("transition-all", {
          "opacity-0": expanded,
        })}
        d="M0.5 3 5.5 3"
        stroke="black"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        className={classNames("transition-all")}
        d={!expanded ? "M0.5 5.5 5.5 5.5" : "M0.5 5.5 5.5 0.5"}
        stroke="black"
        stroke-width="1"
        stroke-linecap="round"
      />
    </svg>
  );
}

const Topbar = (props: { transparent?: boolean; fixed?: boolean }) => {
  const router = useRouter();
  const [expanded, setExpanded] = React.useState(false);
  const threshold = 100;
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [screenSize, setScreenSize] = React.useState("");
  const [bgColorClass, setBgColorClass] = React.useState("bg-gray-600");
  const ref = React.useRef<HTMLDivElement>(null);

  // Update the screenSize state based on window width
  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= Number(process.env.NEXT_PUBLIC_SCREEN_LARGE)) {
      setScreenSize("Full Screen");
    } else if (width >= Number(process.env.NEXT_PUBLIC_SCREEN_MEDIUM)) {
      setScreenSize("Half Screen");
    } else if (width >= Number(process.env.NEXT_PUBLIC_SCREEN_SMALL)) {
      setScreenSize("Small Screen");
    } else {
      setScreenSize("Mobile Screen");
    }
  };

  // Update bgColorClass whenever screenSize changes
  React.useEffect(() => {
    switch (screenSize) {
      case "Full Screen":
        setBgColorClass("bg-blue-600");
        break;
      case "Half Screen":
        setBgColorClass("bg-green-600");
        break;
      case "Small Screen":
        setBgColorClass("bg-yellow-600");
        break;
      case "Mobile Screen":
        setBgColorClass("bg-red-600");
        break;
      default:
        setBgColorClass("bg-gray-600");
        break;
    }
  }, [screenSize]);

  // Initialize screenSize and set up resize listener on mount
  /* React.useEffect(() => {
    handleResize(); // Set initial screen size
    window.addEventListener("resize", handleResize); // Update on resize

    return () => window.removeEventListener("resize", handleResize); // Clean up on unmount
  }, []); */

  React.useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > threshold) setIsAtTop(false);
      if (window.scrollY < threshold) setIsAtTop(true);
    };

    if (window.scrollY > threshold) setIsAtTop(false);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  React.useEffect(() => {
    if (!ref.current) return;
    if (expanded) {
      ref.current.className = "expandedHambuger";
    } else {
      ref.current.className = "notExpandedHambuger";
    }
  }, [expanded]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // Dynamically import ScrollToPlugin only on the client side
      import("gsap/ScrollToPlugin").then((ScrollToPlugin) => {
        gsap.registerPlugin(ScrollToPlugin);
      });
    }
  }, []);

  const goto = (path: string) => () => {
    if (router.pathname === path) setExpanded(false);
  };


  return (
    <>
      {expanded && (
        <div
          className={`fixed top-0 left-0 z-[9] w-screen sm:flex sm:flex-col sm:items-center sm:justify-center ${screenSize === "Mobile Screen" ? "h-auto" : "h-screen sm:w-full  py-[1vh] bg-custom-bg sm:shadow-md"} md:hidden`}
        >
          {/* Dropdown */}
          <div
            className={`flex flex-grow cursor-pointer flex-col text-center text-6xl mobile:my-[5vh] mobile:pb-[5vh] h-full`}
          >
            <Link href="https://www.linkedin.com/company/altostruct/jobs/">
              <span className="text-[1.5rem] hover:underline">Jobba hos oss</span>
            </Link>
            <Link onClick={goto("/")} href="/">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded(false);
                  gsap.to(window, {
                    duration: 0,
                    scrollTo: { y: "#NYHETER", autoKill: false },
                  });
                }}
                className="text-[1.5rem] hover:underline"
              >
                Nyheter
              </span>
            </Link>
            <Link onClick={goto("/")} href="/">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded(false);
                  gsap.to(window, {
                    duration: 0,
                    scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                  });
                }}
                className="text-[1.5rem] hover:underline"
              >
                Kontakt
              </span>
            </Link>
            <div className=" flex flex-grow flex-col justify-end">
              <TextWrap>
                <div className="flex justify-end p-[1vh]">
                  <span
                    onClick={() => window.open("https://www.linkedin.com/company/altostruct/jobs/", "_self")}
                    className="text-[1rem] text-bold text-custom-black">LinkedIn
                  </span>
                </div>
              </TextWrap>

            </div>

          </div>
        </div>
      )}

      {/* /* md:w-10/12 md:m-auto md:flex-row md:justify-between md:space-y-0 md:bg-green-500
            lg:bg-blue-500 */}

      {/* Topbar */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-custom-bg">
        <header className="w-full">
          <div className="mx-auto mobile:px-[1vh] md:px-[15vh]  max-w-[1920px]">
            <div className="flex lg:h-[9vh] md:h-[9vh] sm:h-[8vh] mobile:h-[8vh] items-stretch">
              {/* Mobile & Small Screen layout */}
              <div className="flex w-full items-center justify-between px-4 md:hidden">
                <Link onClick={goto("/")} href="/" className="w-16">
                  <Image
                    className="max-h-full w-full max-w-full"
                    alt="logo"
                    src="/images/v2/alto_logo.png"
                    width={64}
                    height={64}
                  />
                </Link>
                <Hamburger expanded={expanded} onClick={() => setExpanded(!expanded)} />
              </div>

              {/* Desktop layout */}
              <div className="hidden h-full w-full items-stretch justify-between md:flex">
                {/* Logo and Button section */}
                <div className="flex items-center gap-4">
                  <div className="flex h-full items-center bg-custom-bg">
                    <Link onClick={goto("/")} href="/" className="flex items-center">
                      <Image
                        className="h-auto w-auto"
                        alt="logo"
                        src="/images/v2/alto_logo.png"
                        width={128}
                        height={32}
                      />
                    </Link>
                  </div>
                  <Button
                    className=""
                    variant="custom"
                    icon={<FaArrowRight />}
                    link="https://www.linkedin.com/company/altostruct/posts/?feedView=all"
                    label="Arbeta med oss"
                    textColor="text-custom-text"
                  />
                </div>

                {/* Navigation items */}
                <div className="flex items-center gap-6">
                  <AnimatedText onClick={() => scrollToSection("SYFTE")}>
                    <p className="md:text-sm lg:text-lg">Syfte</p>
                  </AnimatedText>
                  <AnimatedText onClick={() => scrollToSection("KONSULTER")}>
                    <p className="md:text-sm lg:text-lg">Konsulter</p>
                  </AnimatedText>
                  <AnimatedText onClick={() => scrollToSection("KUNDER")}>
                    <p className="md:text-sm lg:text-lg">Kunder</p>
                  </AnimatedText>
                  <AnimatedText onClick={() => scrollToSection("OMOSS")}>
                    <p className="md:text-sm lg:text-lg">Om oss</p>
                  </AnimatedText>
                  <AnimatedText onClick={() => scrollToSection("NYHETER")}>
                    <p className="md:text-sm lg:text-lg">Nyheter</p>
                  </AnimatedText>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      gsap.to(window, {
                        duration: 0,
                        scrollTo: { y: "#KONTAKTAOSS", autoKill: false },
                      });
                    }}
                    label="Kontakta oss"
                    className=""
                    variant="primary"
                  />
                </div>
              </div>
            </div>
          </div>
          <AnimatedLine expanded={!expanded && !isAtTop} />
        </header>
      </div>

      {/* For debugging window size */}
      {/* <div
        className={`flex items-center justify-center text-white ${bgColorClass} h-10 w-full text-lg`}
      >
        <span>{screenSize}</span>
      </div> */}
    </>
  );
};

const scrollToSection = (sectionId: string) => {
  gsap.to(window, {
    duration: 0,
    scrollTo: { y: `#${sectionId}`, autoKill: false },
  });
};

export default Topbar;
