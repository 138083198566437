import React, { useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentfulImage } from '@components/Contentful';
import blogPostFields from 'utils/getBlogPosts';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import required modules
import { Navigation } from 'swiper/modules';

// Import the Swiper type
import type { Swiper as SwiperType } from 'swiper';

const BlogPostCard = () => {
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);

    const formatDate = (blogPostdate: string) => {
        const date = new Date(blogPostdate);
        const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date).split(' ').join(', ');
    };

    // Memoize the sorted blog posts array
    const sortedBlogPosts = useMemo(() => {
        return [...blogPostFields].sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
    }, [blogPostFields]);

    return (
        <div className="w-full relative flex items-center">
            {/* Custom Navigation Buttons */}
            <button
                ref={prevRef}
                className="mobile:invisible md:visible swiper-button-prev-custom absolute left-0 z-10 transform -translate-x-[140%] -translate-y-[120%] bg-custom-button bg-opacity-50 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75"
            >
                <IoIosArrowBack />
            </button>
            <Swiper
                modules={[Navigation]}
                slidesPerView={2}
                spaceBetween={20}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                onSwiper={(swiper: SwiperType) => {
                    setTimeout(() => {
                        if (
                            swiper.params &&
                            swiper.params.navigation &&
                            typeof swiper.params.navigation !== 'boolean'
                        ) {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.destroy();
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }
                    });
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {sortedBlogPosts.map((blogPost, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex flex-col items-center">
                            <div className="w-full h-64 relative border border-gray-300 shadow-lg rounded-3xl overflow-hidden">
                                <ContentfulImage
                                    onClick={() => window.open(`/blog/${blogPost.slug}`, '_self')}
                                    image={blogPost.image}
                                    alt={blogPost.title}
                                    fill
                                />
                            </div>
                            <div className="flex flex-col mt-2 w-full">
                                <span className="text-sm text-gray-500">{formatDate(blogPost.date)}</span>
                                <h2 className="text-lg font-semibold">{blogPost.title}</h2>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <button
                ref={nextRef}
                className="mobile:invisible md:visible swiper-button-next-custom absolute right-0 z-10 transform translate-x-[140%] -translate-y-[120%] bg-custom-button bg-opacity-50 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75"
            >
                <IoIosArrowForward />
            </button>
        </div>
    );
};

export default BlogPostCard;
